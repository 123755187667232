import { connect } from 'react-redux';
import { SignerDocumentModel, OrganizerMetadata, OrganizerFormDataViewModel } from '../../core/domain/models/Organizer/Organizer';
import { ApplicationState } from "../../store/index";
import { actionCreators as OrganizerStore } from '../../store/Organizer/OrganizerStore';
import { actionCreators as OrganizerMetadataStore } from '../../store/Organizer/OrganizerMetadataStore';
import { actionCreators as PreviewStore } from '../../store/Organizer/PreviewStore';
import * as  CoverPageStore from "../../store/CoverPage/CoverPageStore";
import { createLoadingSelector } from '../../store/selectors';
import { ClientView } from './ClientView';
import { actionCreators as UploadedDocumentStore } from '../../store/UploadedDocument/UploadedDocumentStore';
import { actionCreators as PreparerMessageStore } from '../../store/Organizer/PreparerMessageStore';
import { actionCreators as accountAction } from '../../store/Common/AccountStore';

const loadingSelector = createLoadingSelector(['HEADERINFO', 'SUMMARY_DETAILS',
    'TAXING_AUTHORITY', 'DOCUMENT_REVIEW_MODEL', 'DOCUMENT_SETTINGS']);

function mapStateToProps(state: ApplicationState) {
    return {
        organizerMetadata: state.organizerMetadata,
        previewStatus: state.previewStatus.previewStatus,
        uploadedDocumentStatus: state.uploadedDocumentStore,
        preparerMessageStore: state.preparerMessage,
        headerInfo: state.headerInfo,
    };
}
//temperroy fix for webpack failure need to invetigate further when we have time
const mapDispatchToProps = (dispatch: any) => {
    return {

        submitSignedDocument: (clientId: string,
            organizerForms: OrganizerFormDataViewModel,
            successCallback?: () => void,
            failureCallback?: () => void) => dispatch(OrganizerStore.submitSignedDocument(clientId, organizerForms, successCallback, failureCallback)),

        finishDocumentSign: (clientId: string,
            organizerForms?: OrganizerFormDataViewModel,
            successCallback?: () => void,
            failureCallback?: () => void) => dispatch(OrganizerStore.finishDocumentSign(clientId, organizerForms, successCallback, failureCallback)),

        requestOrganizerMetadata: (clientId: string) => dispatch(OrganizerMetadataStore.requestOrganizerMetadata(clientId)),

        addUploadedDocument: (clientId: string,
            uploadedDocument: any,
            successCallback?: () => void,
            failureCallback?: () => void) => dispatch(OrganizerStore.addUploadedDocument(clientId, uploadedDocument, successCallback, failureCallback)),

        deleteUploadedDocument: (clientId: string,
            id: number,
            successCallback?: () => void,
            failureCallback?: () => void) => dispatch(OrganizerStore.deleteUploadedDocument(clientId, id, successCallback, failureCallback)),

        requestUploadedDocuments: (clientId: string,
            forceRefresh?: boolean) => dispatch(OrganizerStore.requestUploadedDocuments(clientId, forceRefresh)),

        requestPreviewDocumentStatus: (clientGuid: string,
            callback?: () => void) => dispatch(PreviewStore.requestPreviewDocumentStatus(clientGuid, callback)),

        requestUploadedDocumentStatus: (clientId: string) =>
            dispatch(UploadedDocumentStore.requestUploadedDocumentStatus(clientId)),

        requestOrganizerPreparerMessage: (clientId: string) => dispatch(PreparerMessageStore.requestOrganizerPreparerMessage(clientId)),

        requestHeaderInfo: (id: string, isPreview: boolean) => dispatch(accountAction.requestHeaderInfo(id, isPreview)),
    }
}


const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ClientView);
