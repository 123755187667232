import * as React from "react";
import { RouteComponentProps } from "react-router";
import { Button } from "react-bootstrap";
import {
	OrganizerMetadata,
	UploadedDocument,
} from "../../core/domain/models/Organizer/Organizer";
import { container } from "../../startup/inversify.config";
import { TYPES } from "../../startup/types";
import {
	OrganizerUploadedDocuments,
	PreviewConstants,
	SupportedFilePreviewTypes,
} from "../Common/Constants";
import { UploadDocumentsArea } from "./Parts/UploadDocumentsArea";
import * as OrganizerStore from "../../store/Organizer/OrganizerStore";
import { Footer } from "./Footer/Footer";
import { OrganizerConstants } from "../Common/Constants";
import * as OrganizerMetadataStore from "../../store/Organizer/OrganizerMetadataStore";
import {
	GetDeletedFilesFromStorage,
	IsPreviewModeFromCache,
	IsClientViewFromCache,
} from "../Helper/HelperFunction";
import { BatchUploadedStatus } from "../../core/domain/models/SourceDocument/SourceDocument";
import { MarsNotifier } from "../Common/Notification/MarsNotifier";
import { UploadedDocumentStoreState } from "../../store/UploadedDocument/UploadedDocumentStoreModels";
import { actionCreators as UploadedDocumentStore } from "../../store/UploadedDocument/UploadedDocumentStore";
import { Shimmer } from "../Common/Shimmer/Shimmer";
import { ModalNotification } from "../Common/Notification/ModalNotification";
import { logger } from "../../routes";
import { ISnackbar } from "../../core/utilities/ui/Snackbar";
import { IOrganizerService } from "../../core/Services/OrganizerService/OrganizerService";
import { ILoader } from "../../core/utilities/ui/Loader";
import { DisplayDownloadFile } from "../../core/utilities/DisplayDownloadFile";
import { PreviewUplodedFileModal } from "../Preview/PreviewUploadedFileModal";
import InfoCircleIcon from "../../assets/images/icon-info-circle.svg";

export type UploadModalProps = {
	uploadedDocuments: UploadedDocument[];
	organizerMetadata: OrganizerMetadata;
	uploadedDocumentStore: UploadedDocumentStoreState;
} & typeof OrganizerStore.actionCreators &
	typeof OrganizerMetadataStore.actionCreators &
	typeof UploadedDocumentStore &
	RouteComponentProps<{}>;

interface UploadModalState {
	disableSave: boolean;
	clientId: string;
	client: any;
	filePreviewModal: {
		url: string;
		showPDFPreview: boolean;
		fileType: string | undefined;
		fileName: string;
	};
	completeConfirmationPopup: boolean;
}

const snackbar = container.get<ISnackbar>(TYPES.ISnackbar);
const organizerService = container.get<IOrganizerService>(
	TYPES.IOrganizerService
);
const loader = container.get<ILoader>(TYPES.ILoader);

export class UploadDocumentsModal extends React.Component<
	UploadModalProps,
	UploadModalState
> {
	private _param: any;
	private _uploadingStatus: BatchUploadedStatus = BatchUploadedStatus.Completed;

	constructor(props: UploadModalProps) {
		super(props);
		this.state = {
			disableSave: false,
			clientId: "",
			client: null,
			filePreviewModal: {
				url: "",
				showPDFPreview: false,
				fileType: "",
				fileName: "",
			},
			completeConfirmationPopup: false,
		};
	}

	componentDidMount() {
		this._param = this.props.match.params;
		this.props.requestUploadedDocuments((this._param as any).clientId, false);
		this.setState({ clientId: (this._param as any).clientId });
		this.props.requestOrganizerMetadata((this._param as any).clientId);
		this.getDocumentStatus();
		this.props.requestUploadedDocumentStatus(this._param.clientId);
	}

	componentDidUpdate(
		prevProps: Readonly<UploadModalProps>,
		prevState: Readonly<UploadModalState>,
		snapshot?: any
	): void {
		if (
			this.props.uploadedDocumentStore?.sourceDocumentMetaData?.path !=
			prevProps.uploadedDocumentStore?.sourceDocumentMetaData?.path
		) {
			if (
				SupportedFilePreviewTypes.includes(
					this.props.uploadedDocumentStore.sourceDocumentMetaData?.extension.toLowerCase()
				)
			) {
				this.setState({
					filePreviewModal: {
						...this.state.filePreviewModal,
						url: this.props.uploadedDocumentStore.sourceDocumentMetaData.path,
						showPDFPreview: true,
						fileType:
							this.props.uploadedDocumentStore.sourceDocumentMetaData?.extension?.substring(
								1
							),
						fileName:
							this.props.uploadedDocumentStore.sourceDocumentMetaData.fileName,
					},
				});
			} else if (
				this.props.uploadedDocumentStore.sourceDocumentMetaData?.extension
			) {
				MarsNotifier.Warning(PreviewConstants.PreviewNotSupported, null);
			}
		}
	}

	private getDocumentStatus = () => {
		const _self = this;

		organizerService
			.getDocumentStatusAndSourceFileSettings(this._param.clientId)
			.then(function (response: any) {
				const {
					enabledSourceDocuments,
				} = response.data;
				
				_self.setState({
					client: response.data.client,
				});

				if (!enabledSourceDocuments) {
					window.location.href = `${OrganizerConstants.OrganizerSummaryPageURL}${_self._param.clientId}`;
				}
			});
	};
	private isPreviewOrClientViewMode = () => {
		return IsPreviewModeFromCache() || IsClientViewFromCache();
	};
	private handleUploadedStatusUpdate = (status: BatchUploadedStatus) => {
		this._uploadingStatus = status;
	};

	private handleDocumentUploadComplete = () => {
		const { completeDocumentUpload } = this.props;

		if (!this.checkIfUploadInprogress()) {
			const deletedFiles = GetDeletedFilesFromStorage();
			if (this.isPreviewOrClientViewMode()) {
				this.handleRedirect(
					(IsPreviewModeFromCache()
						? OrganizerConstants.PreviewURL
						: OrganizerConstants.ClientViewURL) + this.state.clientId
				);
				return;
			}
			completeDocumentUpload(this.state.clientId, deletedFiles, () => {
				this.handleRedirect(
					OrganizerConstants.OrganizerSummaryPageURL + this.state.clientId
				);
			});
		} else {
			alert(OrganizerUploadedDocuments.UploadInProgressOnNotifyMessage);
		}
	};

	private changeUploadedDocumentStatusToCompleted = () => {
		const { changeSourceDocumentStatusToCompleted } = this.props;

		if (!this.checkIfUploadInprogress()) {
			if (this.isPreviewOrClientViewMode()) {
				MarsNotifier.Warning(PreviewConstants.FinishMessage, null);
				return;
			}

			changeSourceDocumentStatusToCompleted(
				this._param.clientId,
				this.state.client.clientType,
				0,
				() => {
					this.handleRedirect(
						OrganizerConstants.OrganizerSummaryPageURL + this.state.clientId
					);
				}
			);
		} else {
			alert(OrganizerUploadedDocuments.UploadInProgressOnCompleteMessage);
		}
	};

	private handleUploadedDocumentModification = () => {
		this.setState({ disableSave: false });
	};

	private checkIfUploadInprogress(): boolean {
		return this._uploadingStatus == BatchUploadedStatus.Uploading;
	}

	private handleRedirect = (url: string) => {
		window.location.href = url;
	};

	private changeStatusToCompleted = () => {
		this.props.setUploadedDocumentError();
		this.props.setUploadedDocumentCompleted();
		this.props.requestUploadedDocumentStatus(this._param.clientId);
	};

	onErrorPopupClose = () => {
		const { resetUploadedDocumentError, requestUploadedDocuments } = this.props;
		resetUploadedDocumentError();
		requestUploadedDocuments((this._param as any).clientId, true);
	};
	onHidePDFPreview = () => {
		this.setState({
			filePreviewModal: {
				...this.state.filePreviewModal,
				showPDFPreview: false,
			},
		});
	};

	downloadIndividualSourceDocument = (
		documentId: number,
		fileName: string,
		documentGuid: string,
		year: number
	): void => {
		loader.show();
		const clientId = this._param.clientId;
		organizerService
			.downloadIndividualSourceDocument(
				documentId,
				fileName,
				documentGuid,
				year,
				clientId
			)
			.then(function (response: any) {
				const contentDisposition = response.headers["content-disposition"];
				const fileNameMatch = contentDisposition
					? /filename="?([^"]*)"?;/g.exec(contentDisposition)
					: undefined;
				if (fileNameMatch && fileNameMatch.length > 1) {
					fileName = fileNameMatch[1];
				}
				let displayDownloadFile = new DisplayDownloadFile();
				displayDownloadFile.showFile(response.data, fileName);
				loader.hide();
			})
			.catch(function (error: any) {
				logger.trackError(
					`Download file failed with error ${error.message} for client: ${clientId}`
				);
				loader.hide();
			});
	};

	downloadAllSourceDocuments = (uploadedDocuments: UploadedDocument[]) => {
		const uploadedDocument = uploadedDocuments[0];
		loader.show();
		const clientId = this._param.clientId;
		organizerService
			.downloadAllSourceDocuments(clientId, uploadedDocument.taxYear)
			.then(function (response: any) {
				const contentDisposition = response.headers["content-disposition"];
				const fileNameMatch = contentDisposition
					? /filename="?([^"]*)"?;/g.exec(contentDisposition)
					: undefined;
				if (fileNameMatch && fileNameMatch.length > 1) {
					let fileName = fileNameMatch[1];
					let displayDownloadFile = new DisplayDownloadFile();
					displayDownloadFile.showFile(response.data, fileName);
				}
				loader.hide();
			})
			.catch(function (error: any) {
				logger.trackError(
					`Download file failed with error ${error.message} for client: ${clientId}`
				);
				loader.hide();
			});
	};

	downloadSelectedSourceDocuments = (selectedDocuments: UploadedDocument[]) => {
		const fileIds = selectedDocuments.map((item) => item.id).join(",");
		const clientId = this._param.clientId;
		loader.show();
		organizerService
			.downloadSelectedSourceDocuments(
				clientId,
				fileIds,
				selectedDocuments[0].taxYear
			)
			.then(function (response: any) {
				const contentDisposition = response.headers["content-disposition"];
				const fileNameMatch = contentDisposition
					? /filename="?([^"]*)"?;/g.exec(contentDisposition)
					: undefined;
				if (fileNameMatch && fileNameMatch.length > 1) {
					let fileName = fileNameMatch[1];
					let displayDownloadFile = new DisplayDownloadFile();
					displayDownloadFile.showFile(response.data, fileName);
				}
				loader.hide();
			})
			.catch(function (error: any) {
				logger.trackError(
					`Download file failed with error ${error.message} for document guid ids: ${fileIds}`
				);
				loader.hide();
			});
	};

	showCompleteConfirmationPopup = () => {
		this.setState({ completeConfirmationPopup: true });
	};

	hideCompleteConfirmationPopup = () => {
		this.setState({ completeConfirmationPopup: false });
	};

	public render() {
		const isPreviewOrClientViewMode = this.isPreviewOrClientViewMode();

		const { client, filePreviewModal, completeConfirmationPopup } = this.state;

		const {
			uploadedDocumentStore: {
				isCompleted,
				loading,
				uploadedDocumentStatus,
				isError,
				errorMessage,
			},
			setUploadedDocumentError,
			getSourceDocumentMetadataAsync,
		} = this.props;

		/*Added scroll because of Download list is not visible*/
		return (
			<div style={{ height: "85vh", overflowY: "scroll" }}>
				<div
					style={{
						backgroundColor: "#fff",
						marginTop: "10px",
						paddingTop: "10px",
						minHeight: "calc(100% - 125px)",
					}}
					className="col-lg-10 offset-lg-1 offset-md-1"
				>
					<h2 style={{ color: "#017cff", fontSize: "x-large" }}>
						Source Documents Upload
					</h2>
					<hr />
					<div
						className={"col-lg-12 no-padding upload-documents-title"}
						style={{ marginLeft: "10px" }}
					>
						<img className="info" src={InfoCircleIcon} alt="" />
						<span>
							{loading || !client ? (
								<Shimmer width={50} />
							) : !isCompleted ? (
								OrganizerUploadedDocuments.ModalBodyText
							) : uploadedDocumentStatus &&
							  client.clientType == uploadedDocumentStatus.actedBy ? (
								" You have completed source document upload."
							) : (
								` ${client.spouseName} has completed source document upload.`
							)}
						</span>
						<div className="source-document-upload-model-imp-msg">
							<b>Important!</b>{" "}
							{OrganizerUploadedDocuments.SourceDocumentUploadWarningmsg}
						</div>
					</div>
					<UploadDocumentsArea
						clientId={this.state.clientId}
						uploadedDocuments={this.props.uploadedDocuments}
						deleteUploadedDocument={this.props.deleteUploadedDocument}
						addDocument={this.props.addUploadedDocument}
						requestUploadedDocuments={this.props.requestUploadedDocuments}
						updateUploadedStatus={this.handleUploadedStatusUpdate}
						onUploadedDocumentModification={
							this.handleUploadedDocumentModification
						}
						disabled={isPreviewOrClientViewMode || isCompleted || loading}
						isCompleted={isCompleted}
						changeStatusToCompleted={this.changeStatusToCompleted}
						setUploadedDocumentError={setUploadedDocumentError}
						downloadIndividualSourceDocument={
							this.downloadIndividualSourceDocument
						}
						downloadAllSourceDocuments={this.downloadAllSourceDocuments}
						downloadSelectedSourceDocuments={
							this.downloadSelectedSourceDocuments
						}
						getSourceDocumentMetadataAsync={getSourceDocumentMetadataAsync}
					/>
					<PreviewUplodedFileModal
						url={filePreviewModal.url}
						fileName={filePreviewModal.fileName}
						fileType={filePreviewModal.fileType}
						onHidePDFPreview={this.onHidePDFPreview}
						showPDFPreview={filePreviewModal.showPDFPreview}
						customClass="source-upload-preview"
					/>
				</div>
				<Footer
					onFinish={this.handleDocumentUploadComplete}
					onComplete={this.showCompleteConfirmationPopup}
					completeDisabled={
						this.state.disableSave ||
						isCompleted ||
						loading ||
						!this.props.uploadedDocuments.length
					}
					isCompleted={isCompleted}
				/>
				{isError && (
					<ModalNotification
						show={isError}
						modalBodyText={errorMessage}
						okButtonEnabled={true}
						okButtonText={"Ok"}
						onOkButtonClick={this.onErrorPopupClose}
					/>
				)}

				{completeConfirmationPopup && (
					<ModalNotification
						onHide={this.hideCompleteConfirmationPopup}
						closeButton={true}
						show={completeConfirmationPopup}
						modalBodyText={
							<div className="font-size-14p">
								Have you finished uploading all necessary source documents?{" "}
								<br /> Once you confirm, you will not be able to upload
								additional documents.
							</div>
						}
						okButtonEnabled={false}
						modalHeaderText={
							<div className="font-size-16p font-weight-bold">
								Are You Sure?
							</div>
						}
						footerChildrens={
							<>
								<Button
									data-test-auto="4418D370-E36E-4AE7-BD89-97EF3758CF27"
									className={
										"btn btn-white btn-default btn-default-extended bootbox-cancel"
									}
									variant="outline-light"
									onClick={this.hideCompleteConfirmationPopup}
								>
									Cancel
								</Button>

								<Button
									className={"btn-primary-extended"}
									data-test-auto="0CDC79C8-111C-49C2-A196-AD8CD3D2D363"
									onClick={() => {
										this.changeUploadedDocumentStatusToCompleted();
										this.hideCompleteConfirmationPopup();
									}}
								>
									Confirm
								</Button>
							</>
						}
					/>
				)}
			</div>
		);
	}
}
